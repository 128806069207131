<template>
  <div>
    <v-row class="ma-0 pb-0 our-clients justify-center">
      <v-col cols="12" class="my-2 text-center">
        <h4>{{ $t("company_page.some_clients") }}</h4>
      </v-col>
      <v-col sm="6" md="3" class="text-center">
        <img src="../../assets/img/Client-1.svg" style="object-fit: contain" alt="Google AdWords"/>
      </v-col>
    </v-row>
    <Footer bg-color="#b9b9b9" />
  </div>
</template>
<script>
import { currentDate } from "@/utils";

export default {
  name: "OurClients",
  components: {
    Footer: () => import("@/components/Footer")
  },
  computed: {
    getCurrentYear() {
      return currentDate;
    },
  },
};
</script>

<style scoped lang="scss">
.our-clients {
  color: #000000;
  padding: 0 0 40px 0;

  h4 {
    font-size: 30px;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
}
</style>
